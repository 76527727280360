import React from "react";

const Hero = ({ img, dark, children, className }) => {
  return (
    <div
      className={`hero ${className}`}
      style={{
        color: dark ? "#000" : "#fff",
        backgroundImage: `url(${img})`,
      }}
    >
      {children}
    </div>
  );
};

export default Hero;
