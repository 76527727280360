import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";
import * as Yup from "yup";
import * as emailjs from "emailjs-com";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { useFormik } from "formik";
import { FiCheckCircle, FiMapPin } from "react-icons/fi";
import {
  Box,
  Center,
  Heading,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  HStack,
  VStack,
  Textarea,
  Button,
} from "@chakra-ui/react";

import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Container from "../components/Container";
import Content, { HTMLContent } from "../components/Content";

const validationSchema = Yup.object().shape({
  name: Yup.string().max(50, "För långt namn").required("Vänligen fyll i ditt namn"),
  email: Yup.string().email("Ogiltig e-postadress").required("Vänligen fyll i din e-postadress"),
  message: Yup.string().max(500, "För långt meddelande").required("Vänligen fyll i ett meddelande"),
});
export const ContactPageTemplate = ({ title, heroImage, helmet, heroVisible, content, contentComponent }) => {
  const [viewport, setViewport] = useState({
    latitude: 59.3588639,
    longitude: 18.0018705,
    zoom: 15,
  });

  const [isSent, setIsSent] = useState(false);
  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: "",
      email: "",
      phone: "",
      business: "",
      message: "",
    },
    onSubmit: (values) => {
      var service_id = "default_service";
      var template_id = "crossfittegen_kontakt";
      emailjs.send(service_id, template_id, values, "user_VrE4A1Pifih6U1JMhdZRR");
      setIsSent(true);
    },
  });

  const PageContent = contentComponent || Content;

  return (
    <>
      {helmet || ""}
      {heroVisible && (
        <Hero img={!!heroImage.childImageSharp ? heroImage.childImageSharp.fluid.src : heroImage}>
          <Heading as="h1" size="2xl">
            {title}
          </Heading>
        </Hero>
      )}
      <Container>
        <Box pt="3rem" pb="2rem">
          {!heroVisible && (
            <Heading as="h1" size="xl" pb={6}>
              {title}
            </Heading>
          )}
          {!isSent ? (
            <form onSubmit={formik.handleSubmit} className="uk-form-stacked">
              <Heading as="h3" size="md" pb="2">
                Lämna ett meddelande
              </Heading>

              <VStack>
                <HStack width="100%" py="2" spacing="6">
                  <FormControl id="first-name" isInvalid={formik.errors.name && formik.touched.name}>
                    <FormLabel>Ditt namn</FormLabel>
                    <Input
                      type="text"
                      name="name"
                      placeholder=""
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                    <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                  </FormControl>

                  <FormControl id="email" isInvalid={formik.errors.email && formik.touched.email}>
                    <FormLabel>Din e-postadress</FormLabel>
                    <Input
                      type="email"
                      name="email"
                      placeholder=""
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                  </FormControl>
                </HStack>
              </VStack>

              <VStack>
                <HStack width="100%" py="2" spacing="6">
                  <FormControl id="phonenumber">
                    <FormLabel>Telefonnummer</FormLabel>
                    <Input
                      type="tel"
                      name="phone"
                      placeholder=""
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                    />
                    <FormErrorMessage>{formik.errors.phone}</FormErrorMessage>
                  </FormControl>

                  <FormControl id="business">
                    <FormLabel>Företag</FormLabel>
                    <Input
                      type="text"
                      name="business"
                      placeholder=""
                      onChange={formik.handleChange}
                      value={formik.values.business}
                    />
                    <FormErrorMessage>{formik.errors.business}</FormErrorMessage>
                  </FormControl>
                </HStack>
                <Box width="100%" py="2">
                  <FormControl id="message" isInvalid={formik.errors.message && formik.touched.message ? true : false}>
                    <FormLabel>Meddelande</FormLabel>
                    <Textarea
                      name="message"
                      size="lg"
                      rows="5"
                      onChange={formik.handleChange}
                      value={formik.values.message}
                    />
                    <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
                  </FormControl>
                </Box>
              </VStack>

              <Box py="2">
                <Button type="submit">Skicka</Button>
              </Box>
            </form>
          ) : (
            <Center>
              <VStack>
                <FiCheckCircle color="green" size="64" />
                <Heading as="h2" size="xl">
                  Ditt meddelande har skickats!
                </Heading>
                <Text>Vi återkommer inom kort.</Text>
              </VStack>
            </Center>
          )}
        </Box>
        <Box py="2rem">
          <PageContent className="content" content={content} />
        </Box>
        <Box pt="2rem" pb="3rem">
          <ReactMapGL
            {...viewport}
            width="100%"
            height="400px"
            mapStyle="mapbox://styles/mapbox/outdoors-v11"
            onViewportChange={(nextViewport) => setViewport(nextViewport)}
            mapboxApiAccessToken="pk.eyJ1IjoiZWRkeW4iLCJhIjoiY2psY20zY3BhMDIweDNrcjBydTc5NXAyZyJ9.CmVa7hkw9qC7kvkLqeQaXQ"
            scrollZoom={false}
            touchZoom={false}
          >
            <Marker latitude={59.3588639} longitude={18.0018705} anchor="top">
              <FiMapPin
                size="30"
                style={{
                  transform: `translate(${-30 / 2}px,${-30}px)`,
                }}
                title="Besök oss här!"
              />
            </Marker>
            <Box pos="absolute" right="0" p="1rem">
              <NavigationControl />
            </Box>
          </ReactMapGL>
        </Box>
      </Container>
    </>
  );
};

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  heroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heroVisible: PropTypes.bool,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const ContactPage = ({ data, ...props }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout {...props}>
      <ContactPageTemplate
        heroImage={post.frontmatter.heroImage}
        heroVisible={post.frontmatter.heroVisible}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        helmet={
          <Helmet>
            <title>{`${post.frontmatter.title}`}</title>
          </Helmet>
        }
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heroVisible
        heroImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
